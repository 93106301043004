@tailwind base;
@tailwind components;
@tailwind utilities;

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nanum-gothic-regular {
  font-family: 'Nanum Gothic', sans-serif;
  font-weight: 400;
  font-style: normal;
}
.nanum-myeongjo-bold {
  font-family: 'Nanum Myeongjo', serif;
  font-weight: 700;
  font-style: normal;
}
